
        <template>
          <div class="vc-snippet-doc">
            <h2>安装</h2>
<h3>npm 安装</h3>
<p>推荐使用 npm 的方式安装，它能更好地和 <a href="https://webpack.js.org/" target="_blank">webpack</a> 打包工具配合使用。</p>
<pre class="hljs"><code>npm i ixu-ui -S
</code></pre>
<h3>CDN</h3>
<p>目前可以通过 <a href="https://unpkg.com/ixu-ui/" target="_blank">lib.bdsaas.com/ixu-ui</a> 获取到最新版本的资源，在页面上引入 js 和 css 文件即可开始使用。</p>
<pre class="hljs"><code><span class="hljs-comment">&lt;!-- 引入样式 --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">link</span> <span class="hljs-attr">rel</span>=<span class="hljs-string">"stylesheet"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"https://lib.bdsaas.com/ixu-ui/lib/ixu.css"</span>&gt;</span>
<span class="hljs-comment">&lt;!-- 引入组件库 --&gt;</span>
<span class="hljs-tag">&lt; <span class="hljs-attr">script</span> <span class="hljs-attr">src</span>=<span class="hljs-string">"https://lib.bdsaas.com/ixu-ui/lib/ixu.umd.min.js"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
<div class="tip">
<p>我们建议使用 CDN 引入 ixu-ui 的用户在链接地址上锁定版本，以免将来 ixu-ui 升级时受到非兼容性更新的影响。锁定版本的方法请查看 <a href="https://unpkg.com" target="_blank">unpkg.com</a>。</p>
</div>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            
           }
         }
       </script>
       <style lang='scss'>
         
       </style>